<template>
	<div id="register">
		<div class="registerBox">
			<div class="banner">
				<div class="logo">
					<img src="../../assets/logo3.png" alt="">
					<!-- <p><b>万木智会</b>-患教会系统</p> -->
				</div>
				<img class="back" src="../../assets/imgs/loginBack.png" alt="">
			</div>
			<div class="box">
				<h1>注册账号</h1>
				<ul class="steps">
					<template v-for="(item,i) in steps">
						<li :key="i" v-if="item.active<3">
							<div class="icon" v-if="item.active==2">
								0 {{i+1}}
							</div>
							<div :class="item.active == 1?`icon active`:`icon`" v-if="item.active==1">
								<i class="el-icon-check" style="font-size:18px;"></i>
							</div>
							{{item.title}}
						</li>
					</template>
				</ul>
				<div class="StepBox">Step 1/{{steps.length}}</div>
				<p class="StepTitle">创建账号</p>
				<el-form :model="registerForm" :rules="rules" ref="registerForm" label-position="top">
					<el-form-item label="手机号" prop="phoneNumber">
						<el-input clearable v-model="registerForm.phoneNumber" placeholder="请输入手机号" maxlength="11">
						</el-input>
					</el-form-item>
					<el-form-item label="验证码" prop="verifyCode">
						<el-input clearable v-model="registerForm.verifyCode" maxlength="6" placeholder="请输入验证码">
							<el-button :disabled="disabled" slot="append"
								@click="sendCodeFun()">{{sendCodeText}}</el-button>
						</el-input>
					</el-form-item>
					<el-form-item label="密码" prop="password">
						<el-input clearable show-password v-model="registerForm.password"
							placeholder="请输入内容"></el-input>
					</el-form-item>
					<el-form-item label="确认密码" prop="confirmPassWord">
						<el-input clearable show-password v-model="registerForm.confirmPassWord"
							placeholder="请输入内容"></el-input>
					</el-form-item>
					<el-form-item>
						<el-checkbox v-model="checked">
							已阅读并同意
							<b @click.stop="agreementShow = true" style="color: #409EFF;">《万木智会--用户使用协议》</b>
						</el-checkbox>
					</el-form-item>
					<el-form-item>
						<div class="options">
							<el-button class="bank" @click="bank()">返回登录</el-button>
							<el-button class="next" @click="onSubmit('registerForm')">下一步</el-button>
						</div>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<el-dialog custom-class="agreementShow" title="用户使用协议" v-if="agreementShow" :visible.sync="agreementShow"
			width="800px" center>
			<div class="main">
				<h4>一、协议概述</h4>
				<br>1.1
				本协议是您（以下简称“用户”）与我们（以下简称“服务提供方”）之间关于使用“万木智会”系统（以下简称“本系统”）所订立的协议。本协议旨在明确用户与服务提供方之间的权利和义务，规范用户使用本系统的行为。
				<br><br>1.2 您在使用本系统提供的服务前，请仔细阅读并充分理解本协议的全部内容。一旦您开始使用本系统，即表示您同意遵守本协议的所有条款和条件。
				<br><br>1.3 服务提供方保留随时修改本协议的权利。修改后的协议一经公布即生效。若您不同意相关变更，请立即停止使用本系统；否则，视为您接受修改后的协议。
				<br>
				<h4>二、用户信息的收集与使用 </h4>
				<br><br>2.1 信息收集范围
				<br>为了向您提供更好的服务，本系统将收集以下信息：
				<br>· 个人身份信息：包括姓名、性别、出生日期、身份证号码、职业资格证等；
				<br>· 联系方式：包括电话号码、电子邮箱等；
				<br>· 职业相关信息：包括就业医院、科室、职称等；
				<br>· 验证信息：通过人脸识别等方式进行身份验证。
				<br>· 行为信息：您在本平台参与的所有会议记录。
				<br><br>2.2 信息使用目的
				<br>收集的信息将仅用于以下用途：
				<br>· 提供核心服务功能，如电子协议签署与支付进度查询；
				<br>· 确保身份真实性和账户安全；
				<br>· 遵守法律法规及监管要求；
				<br>· 提供个性化服务体验。
				<br><br>2.3 信息保护措施
				<br>· 服务提供方承诺采取严格的数据保护措施，防止信息被泄露、滥用或未经授权访问；
				<br>· 所有敏感信息将通过加密技术进行存储和传输，确保数据安全。
				<br><br>2.4 信息共享与披露
				<br>在以下情况下，我们可能会共享您的信息：
				<br>· 与为完成服务所必要的第三方（如支付机构、认证机构）共享；
				<br>· 根据法律法规的要求披露；
				<br>· 为保护用户及服务提供方的合法权益。
				<br>
				<h4>三、用户的权利与义务</h4>
				<br><br>3.1 用户的权利
				<br>· 查询与修改：您有权随时查询、更正您的个人信息；
				<br>· 删除与撤回：您可要求删除部分信息或撤回某些授权，但可能导致部分功能不可用；
				<br>· 监督与投诉：如发现信息被误用或泄露，您可立即通知服务提供方并要求采取措施。
				<br><br>3.2 用户的义务
				<br>· 妥善保管您的账户信息，不得将账户信息提供给他人；
				<br>· 遵守法律法规和本协议，不利用本系统从事任何违法活动；
				<br>· 提供真实、准确的信息，因提供虚假信息导致的后果由您自行承担。
				<br>
				<h4>四、服务使用规则</h4>
				<br><br>4.1 禁止行为
				<br>在使用本系统时，用户不得从事以下行为：
				<br>· 上传、传播违法、侵权、虚假的内容；
				<br>· 干扰或破坏本系统的正常运行；
				<br>· 未经授权访问、修改或删除数据。
				<br><br>4.2 处理措施
				<br>对于违反上述规则的行为，服务提供方有权采取警告、限制功能、暂停或终止服务等措施，并保留追究法律责任的权利。
				<br>
				<h4>五、法律责任</h4>
				<br><br>5.1 服务提供方责任
				<br>若因服务提供方违反法律法规或本协议导致用户权益受损，服务提供方将依法承担赔偿责任。
				<br><br>5.2 用户责任
				<br>因用户违反本协议或相关法律法规导致服务提供方或第三方遭受损失的，用户应承担相应赔偿责任。
				<br><br>5.3 免责条款
				<br>因不可抗力、第三方原因或用户自身过失导致的损失，服务提供方不承担责任。
				<br>
				<h4>六、其他条款</h4>
				<br><br>6.1 协议解释权
				<br>本协议的解释权归服务提供方所有。
				<br><br>6.2 争议解决
				<br>本协议适用中华人民共和国法律。因本协议引起的争议，双方应协商解决；协商不成的，可向服务提供方所在地有管辖权的法院提起诉讼。
				<br><br>6.3 协议独立性
				<br>若本协议部分条款被认定为无效或不可执行，其余条款仍保持有效。
				<br><br>6.4 用户通知
				<br>服务提供方将通过本系统或其他渠道发布协议更新及通知，用户有责任及时查阅。
				<br>
				<br>请您在使用本系统前仔细阅读本协议。如有疑问，请联系客户服务。感谢您对“万木智会”系统的支持与信任！
				<br>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="agreementShow = false">取 消</el-button>
				<el-button type="primary" @click="agreementShow = false;checked = true">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		sendCode,
		register
	} from '@/request/api.js';
	export default {
		name: 'register',
		data() {
			return {
				agreementShow: false,
				checked: true,
				seconds: 60,
				disabled: false,
				sendCodeText: '获取验证码',
				registerForm: {
					phoneNumber: '',
					verifyCode: '',
					password: '',
					confirmPassWord: ''
				},
				rules: {
					phoneNumber: [{
						required: true,
						message: '请输入手机号',
						trigger: 'change'
					}, {
						validator: (rule, value, callback) => {
							if (!/^1[3-9]\d{9}$/.test(value)) {
								callback(new Error('请输入正确的手机号!'));
							} else {
								callback();
							}
						},
						trigger: 'blur'
					}],
					verifyCode: [{
						required: true,
						message: '请输入验证码',
						trigger: 'change'
					}, {
						min: 4,
						max: 6,
						message: '请输入正确的验证码格式',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'change'
					}, {
						min: 4,
						message: '请至少输入6位字符的密码',
						trigger: 'blur'
					}],
					confirmPassWord: [{
						required: true,
						message: '请输入确认密码',
						trigger: 'change'
					}, {
						validator: (rule, value, callback) => {
							if (value !== this.registerForm.password) {
								callback(new Error('两次输入密码不一致!'));
							} else {
								callback();
							}
						},
						trigger: 'change'
					}]
				},
				steps: [{
					title: '创建账号',
					active: 2
				}, {
					title: '完善信息',
					active: 3
				}, {
					title: '上传认证',
					active: 3
				}, {
					title: '参与项目',
					active: 3
				}],
				objectId: ''
			}
		},
		created() {},
		mounted() {
			localStorage.clear();
		},
		methods: {
			// 获取验证码
			sendCodeFun() {
				if (!this.registerForm.phoneNumber) {
					this.$notify({
						title: '提示',
						type: 'warning',
						message: '请输入手机号',
						position: 'bottom-right'
					});
					return false;
				} else if (!/^1[3-9]\d{9}$/.test(this.registerForm.phoneNumber)) {
					this.$notify({
						title: '提示',
						type: 'warning',
						message: '请输入正确的手机号',
						position: 'bottom-right'
					});
					return false;
				} else {
					sendCode(this.registerForm.phoneNumber)
						.then(res => {
							if (res.code == 200) {
								this.countDown();
								this.$notify({
									title: '提示',
									type: 'success',
									message: '获取验证码成功',
									position: 'bottom-right'
								});
							} else {
								this.$notify({
									title: '错误',
									type: 'error',
									message: res.msg,
									position: 'bottom-right'
								});
							}
						})
				}
			},

			// 倒计时
			countDown() {
				this.disabled = true;
				this.sendCodeText = `${this.seconds}秒后重新获取`;
				if (this.seconds > 0) {
					this.seconds--;
					setTimeout(() => {
						this.countDown(this.seconds)
					}, 1000);
				} else {
					this.sendCodeText = '再次获取';
					this.disabled = false;
					this.seconds = 60;
				}
			},

			agreement() {
				console.log(1)
			},

			onSubmit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						register(this.registerForm).then(res => {
							if (res.code == 200) {
								localStorage.setItem('regToken', JSON.stringify(res.data));
								let routerData = {
									name: 'upLoadInfo'
								}
								if (this.objectId) routerData.objectId = this.objectId;

								this.$notify({
									title: '提示',
									type: 'success',
									message: '创建账号成功',
									position: 'bottom-right'
								});
								this.$router.push(routerData);
							} else {
								this.$notify({
									title: '错误',
									type: 'error',
									message: res.msg,
									position: 'bottom-right'
								});
							}
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			bank() {
				this.$router.go(-1)
			}
		}
	}
</script>

<style lang="scss" scoped>
	#register {
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(244, 249, 253, 1);

		.registerBox {
			width: 90%;
			max-width: 1200px;
			height: 90%;
			display: flex;
			overflow: hidden;
			border-radius: 20px;
			flex-direction: row;
			box-shadow: 0 0 20px rgba(0, 0, 0, .1);

			.banner,
			.box {
				width: 600px;
				background-color: #fff;
			}

			.banner {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.logo {
					flex: 1;
					width: 100%;
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					padding: 40px 0 0 40px;

					img {
						height: 80px;
					}
				}

				.back {
					width: 400px;
					margin-bottom: 100px;
				}
			}

			.box {
				display: flex;
				align-items: center;
				flex-direction: column;
				padding: 50px;
				overflow: hidden;
				overflow-y: auto;
				box-sizing: border-box;

				h1 {
					font-size: 20px;
					margin-bottom: 30px;
					color: #112950;
				}

				.steps {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-start;
					width: 100%;
					margin-bottom: 20px;

					li {
						display: flex;
						color: #112950;
						font-size: 16px;
						align-items: center;
						margin-right: 16px;

						.icon {
							width: 28px;
							height: 28px;
							background: #007AFF;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 5px;
							border-radius: 5px;
							color: #fff;
							font-size: 14px;
						}

						.active {
							background: #ECF3FE;
							color: #007AFF;
						}
					}
				}

				.StepBox {
					width: 500px;
					font-size: 13px;
					color: #112950;
					margin-bottom: 20px;
				}

				.StepTitle {
					width: 500px;
					font-size: 22px;
					color: #112950;
					border-bottom: 1px solid #E5E5E5;
					padding-bottom: 10px;
					margin-bottom: 20px;
				}

				::v-deep .el-form {
					width: 500px;

					.el-form-item__label {
						padding: 0;
						line-height: 1;
						color: #112950;
					}

					.el-input__inner {
						border: none;
						border-bottom: 1px solid #E5E5E5;
						border-radius: 0;
					}

					.el-input-group__append {
						background-color: #fff;
						border: none;
						border-bottom: 1px solid #E5E5E5;
					}

					.el-checkbox {
						display: flex;
						align-items: center;
					}

					.options {
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;

						.el-button {
							width: 300px;
							height: 50px;

						}

						.next {
							background-color: #007AFF;
							color: #fff;
						}

						.back {}
					}
				}

			}
		}
	}
</style>